@font-face{
    font-family: 'Whiskey Bite';
    src: url(./fonts/WhiskeyBite-Regular.otf);
}

.main{
    background: black;
    padding-top:5vh;
    padding-bottom: 5vh;
    text-align: center;
}
.para{
    font-family: 'Whiskey Bite';
    font-size:2em;
    color:rgb(207, 207, 207);
    text-align: center;
    width:90vw;
    padding-left:5vw;
}
.snIcons{
    text-align: center;
    padding:5vh;
    color:rgb(207, 207, 207);
    font-size:2.5em;
}
.icn{
    margin-left:2.5vw;
    margin-right:2.5vw;
}
a{
    color:rgb(207, 207, 207);
}
@media screen and (max-width:550px){
    .para{
        font-size:1.5em;
    }
}