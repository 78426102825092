body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face{
  font-family: 'Whiskey Bite';
  src: url(/static/media/WhiskeyBite-Regular.7d582f54.otf);
}
@font-face{
  font-family: 'Balamoa Normal';
  src: url(/static/media/Balamoa.c2317451.ttf);
}


.App{
  font-size: 16px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.intro {
  height: 100vh;
  background: black;
  min-height: 825px;
}
.myImg {
  display: none;
  height: 100%;
  float:right;
}
.imgDiv{
  float: right;
  height: 100%;
  width:40%;
  background : url(/static/media/me.eeff4a46.jpg);
  background-size:cover;
  box-shadow: 250px -100px 2000px rgba(0,0,0,1) inset;
}
.bigLogoDiv{
  position: absolute;
  padding-top: 2vh;
  padding-left:2vh;
}
.smallLogoDiv{
  display: none;
}
.logo{
  position: absolute;
}
.name{
  font-family: 'Whiskey Bite';
  position: relative;
  color: rgb(207, 207, 207);
  text-align: right;
  font-size: 5em;
  width: 15%;
  padding-top:30vh;
  padding-left:12%;
}
p{
  margin:0px;
  padding:0px;
}
.vertiLine{
  width:3px;
  height:18em;
  background: rgb(218, 218, 218);
  position: absolute;
  top:30vh;
  left:30%;
  
}
.prof{
  font-family: 'Whiskey Bite';
  color: rgb(207, 207, 207);
  text-align: left;
  font-size: 5em;
  width: 20%;
  padding-left:33%; 
  top:30vh;
  position: absolute;

}
.address{
    font-family: 'Whiskey Bite';
  font-size: 2.5em;
  color: rgb(207, 207, 207);
  text-align: center;
  padding-top:20vh;
}
.aboutDiv{
  height:90vh;
  text-align: center;
  background:black;
}
.aboutImg{
  width: 10vw;
  min-width: 120px;
}
.aboutP{
  font-family: 'Whiskey Bite';
  color: rgb(207, 207, 207);
  width:60vw;
  padding-top:25vh;
  padding-left: 20vw;
  margin-bottom: 10vh;
  font-size: 2em;
}
.currDiv{
  height: 70vh;
  background-image: url(/static/media/bgme.51721482.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

}
.back{
  height:100%;
  background: rgba(0,0,0,0.15);
}
.currP{
  color:white;
  padding-top:11%;
  width:50vw;
  font-size: 1.1em;
  text-align: center;
  position: relative;
  padding-left:5vw;
}
.viewCert{
  margin-top:5vh;
  -webkit-appearance: button;
     -moz-appearance: button;
          appearance: button;
  text-decoration: none;
  color: #000;
  color: initial;
  padding:0.7em 2em 0.7em 2em;
  background:  rgba(255,255,255,0.8);
}
.sd{
  margin-left:15%;
}
.tb{
  margin-left:8%;
}
.currTitle{
  font-size: 2.5em;
}


@media screen and (max-width:1200px){
  .intro{
    height: 90vh;
    background: black;
    text-align: center;
    min-height: 90vh;
  }
  .imgDiv{
    float: center;
    height: 45%;
    width: 100%;
    background: black ;
    box-shadow: none;
  }
  .myImg{
    display:inline-block;
    height:100%;
    border-radius: 50%;
    margin-top:15vh;
    float:none;
  }
  .name{
    font-size: 5em;
    text-align: center;
    width: 100%;
    padding-top: 65vh;
    padding-left: 0%;
  }
  .vertiLine{
    display: none;
  }
  .prof{
    font-size: 3em;
    text-align: center;
    width: 100%;
    padding-left: 0%;
    top:73vh;
    position: absolute;
  }
  .address{
    font-size: 3em;
    text-align: center;
    padding-top:15vh;
  }
  .aboutDiv{
    height:inherit;
    padding-bottom: 10vh;
  }
  .aboutP{
    width:90vw;
    padding-top:30vh;
    padding-left: 5vw;
    margin-bottom: 10vh;
    font-size: 3em;
  }
  .back{
    text-align: center;
  }
  .currP{
    padding-top:23%;
    width:90vw;
    padding-left:5vw;
    position: relative;
    padding-left:5vw;
    font-size:1.5em;
  }
  .sd{
    margin-left:0%;
  }
  .tb{
    margin-left:15%;
  }

  
}
@media screen and (max-width:550px){
  .myImg {
    display: none;
    height: 100%;
    float:right;
  }
  .imgDiv{
    height: 100%;
    background : url(/static/media/me.eeff4a46.jpg);
    background-size: cover;
    background-position: center;
    box-shadow: 0px -35vh 2000px rgba(0,0,0,1) inset;
  }
  .name{
    position: absolute;
    padding-top: 65vh;
    max-width: 100vw;
    font-size: 3em;
  }
  .bigLogoDiv{
    display: none;
  }
  .smallLogoDiv{
    position: absolute;
    display: block;
    padding-top: 2vh;
    padding-left:2vh;
  }
  .prof{
    top:73vh;
  }
  .address{
    position: center;
    top:75vh;
    font-size: 2em;
  }
  .aboutP{
    font-size:1.5em;
  }
  .currTitle{
    font-size: 2em;
  }
  .currP{
    padding-top:25%;
    font-size: 1.1em;
  }
  .tb{
    margin-left:5vw;
  }
}

@font-face{
    font-family: 'Balamoa Normal';
    src: url(/static/media/Balamoa.c2317451.ttf);
  }
  


.projects{
    background: #f5f7f9;
    padding-bottom: 2vh;
}

.heading{
    font-family: 'Balamoa Normal';
    padding-top:10vh;
    padding-left:10vw;
    font-size:5em;
}
.workSections{
    width:70%;
    margin-top:5vh;
    margin-left:15%;
    background:white;
    text-align: center;
    padding-bottom: 5vh;
    margin-bottom: 5vh;
}
.workImgs{
    width:100%;
}
.discription{
    font-family: 'Balamoa Normal';
    font-size:3em;
    width:80%;
    text-align: center;
    padding-left:10%;
    position: relative;
    margin-bottom: 2em;
}
.viewCode {
    background-color:  rgb(39, 39, 39);
    color: white;
    position: relative;
    padding: 1em 1.5em;
    margin-top:2em;
    text-decoration: none;
    text-transform: uppercase;
}
.workHeading{
    display: none;
}
  
.viewCode:hover {
    background-color: black;
}
.internship{
    width:55%;
}
  
@media screen and (max-width:1200px){
    .heading{
        padding-top:5vh;
        font-size:5em;
        float:center;
    }
    .workSections{
        width:90%;
        margin-left:5%;
    }
    .discription{
        text-align: center;
        position: relative;
        padding-top:5vh;
        margin-bottom: 2em;
    }
    .workTitle{
        float:left;
        padding-left:10%;
        font-size:1.5em;
    }
    .internship{
        width:80%;
    }
}

@media screen and (max-width:550px){
    .internship{
        width:100%;
    }
    .discription{
        font-size:2em;
    }
}
@font-face{
    font-family: 'Whiskey Bite';
    src: url(/static/media/WhiskeyBite-Regular.7d582f54.otf);
}

.main{
    background: black;
    padding-top:5vh;
    padding-bottom: 5vh;
    text-align: center;
}
.para{
    font-family: 'Whiskey Bite';
    font-size:2em;
    color:rgb(207, 207, 207);
    text-align: center;
    width:90vw;
    padding-left:5vw;
}
.snIcons{
    text-align: center;
    padding:5vh;
    color:rgb(207, 207, 207);
    font-size:2.5em;
}
.icn{
    margin-left:2.5vw;
    margin-right:2.5vw;
}
a{
    color:rgb(207, 207, 207);
}
@media screen and (max-width:550px){
    .para{
        font-size:1.5em;
    }
}
@font-face{
    font-family: 'Sandbrush Demo';
    src: url("/static/media/sandbrush demo.2eafddbf.ttf");
}


.logoDiv{
    height: inherit;
    width: inherit;
}

.logo{
    font-family: 'Sandbrush Demo';
    font-size:4em;
    border: 0.05em solid black;
    padding-left: 0.2em;
    padding-right: 0.2em;
    margin:0.1em;
}
