@font-face{
    font-family: 'Balamoa Normal';
    src: url(./fonts/Balamoa.ttf);
  }
  


.projects{
    background: #f5f7f9;
    padding-bottom: 2vh;
}

.heading{
    font-family: 'Balamoa Normal';
    padding-top:10vh;
    padding-left:10vw;
    font-size:5em;
}
.workSections{
    width:70%;
    margin-top:5vh;
    margin-left:15%;
    background:white;
    text-align: center;
    padding-bottom: 5vh;
    margin-bottom: 5vh;
}
.workImgs{
    width:100%;
}
.discription{
    font-family: 'Balamoa Normal';
    font-size:3em;
    width:80%;
    text-align: center;
    padding-left:10%;
    position: relative;
    margin-bottom: 2em;
}
.viewCode {
    background-color:  rgb(39, 39, 39);
    color: white;
    position: relative;
    padding: 1em 1.5em;
    margin-top:2em;
    text-decoration: none;
    text-transform: uppercase;
}
.workHeading{
    display: none;
}
  
.viewCode:hover {
    background-color: black;
}
.internship{
    width:55%;
}
  
@media screen and (max-width:1200px){
    .heading{
        padding-top:5vh;
        font-size:5em;
        float:center;
    }
    .workSections{
        width:90%;
        margin-left:5%;
    }
    .discription{
        text-align: center;
        position: relative;
        padding-top:5vh;
        margin-bottom: 2em;
    }
    .workTitle{
        float:left;
        padding-left:10%;
        font-size:1.5em;
    }
    .internship{
        width:80%;
    }
}

@media screen and (max-width:550px){
    .internship{
        width:100%;
    }
    .discription{
        font-size:2em;
    }
}