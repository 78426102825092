@font-face{
  font-family: 'Whiskey Bite';
  src: url(./fonts/WhiskeyBite-Regular.otf);
}
@font-face{
  font-family: 'Balamoa Normal';
  src: url(./fonts/Balamoa.ttf);
}


.App{
  font-size: 16px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.intro {
  height: 100vh;
  background: black;
  min-height: 825px;
}
.myImg {
  display: none;
  height: 100%;
  float:right;
}
.imgDiv{
  float: right;
  height: 100%;
  width:40%;
  background : url(./imgs/me.jpg);
  background-size:cover;
  box-shadow: 250px -100px 2000px rgba(0,0,0,1) inset;
}
.bigLogoDiv{
  position: absolute;
  padding-top: 2vh;
  padding-left:2vh;
}
.smallLogoDiv{
  display: none;
}
.logo{
  position: absolute;
}
.name{
  font-family: 'Whiskey Bite';
  position: relative;
  color: rgb(207, 207, 207);
  text-align: right;
  font-size: 5em;
  width: 15%;
  padding-top:30vh;
  padding-left:12%;
}
p{
  margin:0px;
  padding:0px;
}
.vertiLine{
  width:3px;
  height:18em;
  background: rgb(218, 218, 218);
  position: absolute;
  top:30vh;
  left:30%;
  
}
.prof{
  font-family: 'Whiskey Bite';
  color: rgb(207, 207, 207);
  text-align: left;
  font-size: 5em;
  width: 20%;
  padding-left:33%; 
  top:30vh;
  position: absolute;

}
.address{
    font-family: 'Whiskey Bite';
  font-size: 2.5em;
  color: rgb(207, 207, 207);
  text-align: center;
  padding-top:20vh;
}
.aboutDiv{
  height:90vh;
  text-align: center;
  background:black;
}
.aboutImg{
  width: 10vw;
  min-width: 120px;
}
.aboutP{
  font-family: 'Whiskey Bite';
  color: rgb(207, 207, 207);
  width:60vw;
  padding-top:25vh;
  padding-left: 20vw;
  margin-bottom: 10vh;
  font-size: 2em;
}
.currDiv{
  height: 70vh;
  background-image: url(./imgs/bgme.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

}
.back{
  height:100%;
  background: rgba(0,0,0,0.15);
}
.currP{
  color:white;
  padding-top:11%;
  width:50vw;
  font-size: 1.1em;
  text-align: center;
  position: relative;
  padding-left:5vw;
}
.viewCert{
  margin-top:5vh;
  appearance: button;
  text-decoration: none;
  color: initial;
  padding:0.7em 2em 0.7em 2em;
  background:  rgba(255,255,255,0.8);
}
.sd{
  margin-left:15%;
}
.tb{
  margin-left:8%;
}
.currTitle{
  font-size: 2.5em;
}


@media screen and (max-width:1200px){
  .intro{
    height: 90vh;
    background: black;
    text-align: center;
    min-height: 90vh;
  }
  .imgDiv{
    float: center;
    height: 45%;
    width: 100%;
    background: black ;
    box-shadow: none;
  }
  .myImg{
    display:inline-block;
    height:100%;
    border-radius: 50%;
    margin-top:15vh;
    float:none;
  }
  .name{
    font-size: 5em;
    text-align: center;
    width: 100%;
    padding-top: 65vh;
    padding-left: 0%;
  }
  .vertiLine{
    display: none;
  }
  .prof{
    font-size: 3em;
    text-align: center;
    width: 100%;
    padding-left: 0%;
    top:73vh;
    position: absolute;
  }
  .address{
    font-size: 3em;
    text-align: center;
    padding-top:15vh;
  }
  .aboutDiv{
    height:inherit;
    padding-bottom: 10vh;
  }
  .aboutP{
    width:90vw;
    padding-top:30vh;
    padding-left: 5vw;
    margin-bottom: 10vh;
    font-size: 3em;
  }
  .back{
    text-align: center;
  }
  .currP{
    padding-top:23%;
    width:90vw;
    padding-left:5vw;
    position: relative;
    padding-left:5vw;
    font-size:1.5em;
  }
  .sd{
    margin-left:0%;
  }
  .tb{
    margin-left:15%;
  }

  
}
@media screen and (max-width:550px){
  .myImg {
    display: none;
    height: 100%;
    float:right;
  }
  .imgDiv{
    height: 100%;
    background : url(./imgs/me.jpg)  ;
    background-size: cover;
    background-position: center;
    box-shadow: 0px -35vh 2000px rgba(0,0,0,1) inset;
  }
  .name{
    position: absolute;
    padding-top: 65vh;
    max-width: 100vw;
    font-size: 3em;
  }
  .bigLogoDiv{
    display: none;
  }
  .smallLogoDiv{
    position: absolute;
    display: block;
    padding-top: 2vh;
    padding-left:2vh;
  }
  .prof{
    top:73vh;
  }
  .address{
    position: center;
    top:75vh;
    font-size: 2em;
  }
  .aboutP{
    font-size:1.5em;
  }
  .currTitle{
    font-size: 2em;
  }
  .currP{
    padding-top:25%;
    font-size: 1.1em;
  }
  .tb{
    margin-left:5vw;
  }
}
